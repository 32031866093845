<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important"
            >
              <h4 class="text-capitalize breadcrumb-title">
                Documentation
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8"
                      >Action</span
                    >
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Documentation</span>
                  </li>
                </ul>
              </h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="card card-default card-md mb-4">
              <div class="card-header">
                <h6>Add Help</h6>
              </div>
              <div class="card-body">
                <div class="">
                  <form @submit.prevent="">
                    <div class="form-basic">
                      <div class="form-group mb-25">
                        <label>Enter Tiltle</label>
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          name="title"
                          v-model="$v.doc.title.$model"
                          placeholder="name@example.com"
                        />
                      </div>
                      <div class="form-group mb-25">
                        <label>Enter Description</label>
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          name="title"
                          v-model="$v.doc.description.$model"
                          placeholder="name@example.com"
                        />
                      </div>
                      <div class="form-group mb-25">
                        <div class="d-flex">
                          <label>WYSIWYG</label>
                          <span class="flex-1"></span>
                          <div
                            class="btn-toolbar mb-3"
                            role="toolbar"
                            aria-label="Toolbar with button groups"
                          >
                            <div
                              class="btn-group"
                              role="group"
                              aria-label="First group"
                            >
                              <button type="button" class="btn">
                                <i class="las la-image"></i>
                              </button>

                              <button type="button" class="btn btn-secondary">
                                <i class="las la-video"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div style="position:relative; height:400px">
                          <vue-simplemde
                          v-model.trim="$v.doc.content.$model"
                          ref="markdown-editor"
                          :configs="editorConfig"
                          :autoinit="true"
                          style="z-index: 99999; position:absolute; width:100%"
                        />
                        </div>
                        
                        <!-- <textarea class="form-control" placeholder="Enter Help Contents"></textarea> -->
                      </div>
                      <div class="form-group mb-0">
                        <button
                          type="submit"
                          class="btn btn-lg btn-primary btn-submit"
                        >
                          {{ $route.params.doc ? "Update" : "Add" }}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- ends: .card -->
          </div>
          <!-- ends: col-12 -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import db from "@/plugins/localdb";
export default {
  data() {
    return {
      editorConfig: {},
      doc: {
        title: "",
        description: "",
        content: "",
        created_by: "",
      },
    };
  },
  created() {
    this.doc = this.$route.params.doc;
  },
  methods: {
    async addHelp() {
      this.$v.touch();
      if (this.$v.invalid) {
        return;
      }
      await db.docs.add(this.doc);
    },
    async updateHelp() {
      await db.docs.put(this.doc);
    },
  },
  validations: {
    doc: {
      title: {
        required,
      },
      description: {
        required,
      },
      content: {
        required,
      },
    },
    markdown: {
      required,
    },
  },
};
</script>
